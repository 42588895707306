import React, { useContext, useRef } from 'react';
import { useTheme, css } from '@emotion/react';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { DeviceContext } from 'contexts/DeviceContext';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import mapFontValuesToComponent from 'components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';
import {
  Container,
  Description,
  DescriptionInnerContainer,
  ImageSized,
  Title,
  TitleInnerContainer,
} from './TitleImgDesc.styles';

type TitleImgDescProps = {
  title?: string;
  url?: string;
  alt?: string;
  description?: string | JSX.Element;
};

const TitleImgDesc = ({ title, url, alt, description }: TitleImgDescProps): JSX.Element => {
  const {
    state: {
      components: { cmsEntityComponentHeaderFontValues, styleCmsEntityBodyFont },
      wedding,
    },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);
  const { MEDIA_QUERY } = useTheme();

  const altTag =
    alt ||
    `The Wedding Website of ${wedding?.owner_first_name} ${wedding?.owner_last_name} and ${wedding?.partner_first_name} ${wedding?.partner_last_name}`;

  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  const sectionTitleHeaderFontSizeStyle = css`
    font-size: ${cmsEntityComponentHeaderFontValues.fontSize + 8}px;
    ${MEDIA_QUERY.MOBILE} {
      font-size: ${Math.floor(cmsEntityComponentHeaderFontValues.fontSize * 0.8) + 8}px;
    }
  `;

  const StyledTitle = mapFontValuesToComponent({
    fallback: cmsEntityComponentHeaderFontValues,
    ComponentType: Title,
    responsiveFontSize: true,
    mediaQuery: MEDIA_QUERY,
    excludeFontSize: true,
  });

  const StyledDescription = styleCmsEntityBodyFont(Description);
  const isSinglePageLayout =
    ((wedding?.public_theme_v2?.layout_type as unknown) as string) === 'SINGLE_PAGE';

  const renderImage = () => {
    if (!url) return null;
    return (
      <ImageSized
        src={url}
        alt={altTag}
        style={{ maxHeight: device?.notMobile() ? 416 : 232 }}
        containerWidth={containerWidth}
      />
    );
  };

  return (
    <Container ref={containerRef}>
      {!!title && (
        <StyledTitle css={sectionTitleHeaderFontSizeStyle} alwaysShow={isSinglePageLayout}>
          <TitleInnerContainer>{title}</TitleInnerContainer>
        </StyledTitle>
      )}
      {renderImage()}
      {!!description && (
        <StyledDescription>
          <DescriptionInnerContainer>{description}</DescriptionInnerContainer>
        </StyledDescription>
      )}
    </Container>
  );
};

export default TitleImgDesc;
